<template>

  <div>


    <div class="row">
      <div class="col-md-12 mt-1">
        <b-card no-body  class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
               
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <vs-button type="line" line-origin="left" color="danger" class="mr-5"  @click="filterFildes()">
                    <feather-icon icon="FilterIcon" svgClasses="h-4 w-4" v-if="!filter" /> <feather-icon icon="XCircleIcon" svgClasses="h-4 w-4" v-if="filter" /> Filtrer
                  </vs-button>
                  <b-button variant="primary"  @click.stop="addData()">
                    <span class="text-nowrap">Ajouter nouveau</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="filter">
              <b-col cols="12" md="2">
                <b-form-group label="Artisans" label-for="nom">
                  <b-form-input id="nom" v-model="f_artisan"  autofocus trim placeholder="Artisans"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2">
                <b-form-group label="Abonnement" label-for="abonnement">
                    <v-select
                        v-model="f_abonnement_id"
                        :options="abonnementChoices"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :reduce="abonnement => abonnement.name"
                        input-id="abonnement"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2">
                <b-form-group label="Service" label-for="service">
                    <v-select
                        v-model="f_service_id"
                        :options="serviceChoices"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :reduce="service => service.id"
                        input-id="service"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2">
                <b-form-group label="Departement" label-for="departement">
                    <v-select
                        v-model="f_departement_id"
                        :options="departementChoices"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        multiple
                        :reduce="departement => departement.id"
                        input-id="departement"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2">
                <b-form-group label="Code postal" label-for="nom">
                  <b-form-input id="nom" v-model="f_zipcode"  autofocus trim placeholder="Code postal"/>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

 

  
        <b-table
            class="position-relative"
            responsive
            :fields="tableColumns"
            :items="filteredItems"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
        >


            <template #cell(nom)="data">
            <b-media vertical-align="center">
                <template #aside>
                <b-avatar
                    size="32"
                    :src="data.item.photo"
                    :text="avatarText(data.item.nom)"
                    :variant="light-success"
                    @click.stop="viewData(data.item.id)"
                />
                </template>
                <b-link
                @click.stop="viewData(data.item.id)"
                class="font-weight-bold d-block text-nowrap"
                >
                {{ data.item.nom }}
                </b-link>
            </b-media>
            </template>

            <template #cell(services)="data">
              <span class="services" v-for="service in data.item.services">
                <b-badge variant="light-primary">
                    {{ service.name }}
                  </b-badge>
              </span>
            </template>

             <template #cell(abonnement)="data">
                <b-badge variant="light-primary">
                  {{ data.item.abonnement }}
                </b-badge>
            </template>

      

            <template #cell(actions)="data">
                <b-button variant="flat-info" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-info title="Modifier" @click.stop="editData(data.item.id)"  >
                    <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button variant="flat-danger" class="btn-icon" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-danger title="Supprimer" @click.stop="deleteData(data.item.id)">
                    <feather-icon icon="Trash2Icon" />
                </b-button>
            </template>

        </b-table>

           <div class="mx-2 mb-2">
            <b-row>
    
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
    
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalArtisans"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
    
              </b-col>
    
            </b-row>
          </div>

      </b-card>
    </div>
  </div>

</div>

</template>


<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,
    }  from 'bootstrap-vue'

  import vSelect from 'vue-select'
  import { avatarText } from '@core/utils/filter'
  import { mapGetters, mapActions } from "vuex"

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BFormGroup,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      ToastificationContent,

    

    },

    data() {
        return {
            tableColumns : [
                { label: 'Artisans', key: 'nom', sortable: true },
                { label: 'Email', key: 'email', sortable: true },
                { label: 'Tel', key: 'tel', sortable: true },
                { label: 'Services', key: 'services', sortable: true },
                { label: 'Abonnement', key: 'abonnement', sortable: true },
                { label: 'Contact gratuit', key: 'solde_free_contact', sortable: true },
                { label: 'Solde', key: 'solde', sortable: true },
                
                { label: 'Actions', key: 'actions' },
            ],
            isSortDirDesc : true,
            perPage : 10,
            totalArtisans : 0,
            currentPage : 1,
            perPageOptions : [10, 25, 50, 100],
    
            avatarText,
            f_artisan:"",
            f_zipcode:"",

            f_abonnement_id: null,
            f_service_id: null,
            f_departement_id: null,

            filter : false,
        
        }
    },

    computed: {

      ...mapGetters('artisans', {'artisans' : 'GET_ITEMS'}),
      ...mapGetters('abonnements', {'abonnementChoices' : 'GET_ITEMS'}),
      ...mapGetters('services', {'serviceChoices' : 'GET_ITEMS'}),
      ...mapGetters('departements', {'departementChoices' : 'GET_ITEMS'}),

      filteredItems() {
          let dataFilter = null

          if(this.f_artisan.trim() == ""){
            dataFilter = this.artisans
          }else{
            dataFilter = this.artisans.filter(item => {
              return item.nom.trim().toLowerCase().includes(this.f_artisan.toLowerCase())
            })
          }

          if(this.f_service_id != null && this.f_service_id.length > 0) {
            dataFilter = dataFilter.filter(item => {
              return item.services.findIndex(service => (this.f_service_id.includes(service.id))) > -1
            })
          }

          if(this.f_departement_id != null && this.f_departement_id.length > 0) {
            dataFilter = dataFilter.filter(item => {
              return item.departements.findIndex(service => (this.f_departement_id.includes(service.id))) > -1
            })
          }

          if(this.f_abonnement_id != null && this.f_abonnement_id.length > 0) {
            dataFilter = dataFilter.filter(item => {
              return this.f_abonnement_id.includes(item.abonnement)
            })
          }

          var zipcode=""
          if(this.f_zipcode.trim() != ""){
            dataFilter = this.artisans.filter(item => {
               zipcode = item.zipcode+"";
              return zipcode.includes(this.f_zipcode)
            })
          }

            this.totalArtisans = dataFilter.length
          return dataFilter
      },

  

    },

    methods: {
      ...mapActions('artisans', {'fetchArtisans' : 'fetchItems', 'removeArtisan' : 'removeItem'}),
      ...mapActions('abonnements', {'fetchAbonnements' : 'fetchItems'}),
      ...mapActions('services', {'fetchServices' : 'fetchItems'}),
      ...mapActions('departements', {'fetchDepartements' : 'fetchItems'}),

      notification(variant, title, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: title,
              icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
              text: text,
              variant
          },
        })
      },

      filterFildes() {
        this.filter = !this.filter
        if(!this.filter) {
          this.f_artisan = ""
          this.f_zipcode = ""
          this.f_abonnement_id = null
          this.f_service_id = null
          this.f_departement_id =null
        }
      },

      editData(id) {
          this.$router.push(`/artisan/edit/${id}`);
      },
      viewData(id) {
          this.$router.push(`/artisan/view/${id}`);
      },
      addData(){
        this.$router.push(`/artisan/add/`);
      },
      deleteData(id) {
        this.$swal({
          title: 'Êtes-vous sûr de supprimer cette artisan?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Annuler',
          customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
            if (result.value) {
              this.removeArtisan(id).then(response => {
                if(response.data.success){
                    this.notification('success',  'Suppression', 'L\'artisan est bien supprimé')
                }else
                    this.notification('danger',  'Suppression', 'L\'artisan  n\'est pas supprimé')
              }).catch(() => {
                this.notification('danger',  'Suppression', 'L\'artisan  n\'est supprimé')
              })
            }
        })
        
      }
    
    },

    created() {

      this.fetchArtisans()
      this.fetchAbonnements()
      this.fetchServices()
      this.fetchDepartements()
    },

    watch: {

    }
  }
</script>

<style lang="scss" scoped>
  .services:not(:first-child) {
    margin-left: 5px;
  }

  .per-page-selector {
      width: 90px;
  }

  .media {
      display: flex;
      align-items: center;
  }

  .media-aside {
      margin-right: 0.5rem;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>


